import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAlKAGZujbh5zEJiHUl7YDSBmKDk_CDNow',
  authDomain: 'codex-ppaz.firebaseapp.com',
  projectId: 'codex-ppaz',
  storageBucket: 'codex-ppaz.appspot.com',
  messagingSenderId: '830607034733',
  appId: '1:830607034733:web:6bb5616ff30153b376f264',
  measurementId: 'G-VZ24TBR4ST',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
